<template>
    <div class="pa-3">
        <div class="pa-3 white rounded">
            <v-text-field :value="orderNum" @input="setOrderNum" placeholder="Order number" flat hide-details solo-inverted 
                v-mask="'XXX-#########'" @keydown.enter="loadOrder()" ref="orderNum" @focus="focusField = 'orderNum'" @blur="onBlur"></v-text-field>
        </div>

        <v-card flat class="mt-3" :loading="loadingView">
            <v-card-text v-show="!compact" :class="order != null && order.changes.length > 0 ? 'modified rounded' : 'rounded'">
                <v-row v-if="order != null">
                    <v-col cols="4">
                        <div class="subtitle-1 mb-3">Order Information</div>

                        <div class="text-left">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip :color="orderStatusColor(order.status)" label class="white--text mb-3 headline" 
                                        v-bind="attrs" v-on="on">
                                        {{ order.foreignId }}
                                    </v-chip>
                                </template>
                                <span>{{ order.status }}</span>
                            </v-tooltip>
                        </div>

                        <v-simple-table>
                            <tbody>
                                <tr>
                                    <th>Status</th>
                                    <td>{{ order.status }}</td>
                                </tr>
                                <tr>
                                    <th>Pickup location</th>
                                    <td>[{{ order.pickupLocation.wintuxID}}] {{ order.pickupLocation.name }}  |  {{ order.pickupLocation.phone }}</td>
                                </tr>
                                <tr>
                                    <th>Book date</th>
                                    <td>{{ formatDate( order.orderDate, 'YYYY-MM-DD @ hh:mm a' ) }}</td>
                                </tr>
                                <tr>
                                    <th>Pickup date</th>
                                    <td>{{ formatDate( order.wantDate, 'YYYY-MM-DD @ hh:mm a' ) }}</td>
                                </tr>
                                <tr>
                                    <th>Use date</th>
                                    <td>{{ order.useDate }}</td>
                                </tr>
                                <tr>
                                    <th>Return date</th>
                                    <td>{{ order.returnDate }}</td>
                                </tr>
                                <tr>
                                    <th>Customer type</th>
                                    <td>{{ order.eventRole }}</td>
                                </tr>
                                <tr>
                                    <th>Event</th>
                                    <td>{{ order.eventId == '0' ? '' : order.eventId }}</td>
                                </tr>
                                <tr>
                                    <th>Clerk</th>
                                    <td>{{ order.employee }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>

                        <div class="subtitle-1 my-3">Comments</div>
                        <div>{{ order.comments == '' ? 'No comments available' : order.comments }}</div>
                    </v-col>

                    <v-col cols="4">
                        <div class="subtitle-1 mb-3">Customer Information</div>
                        <customer-info :customer="order.customer" :edit="false" :avatar="false" show-measurements />
                    </v-col>

                    <v-col cols="4">
                        <div class="subtitle-1 mb-3">Items</div>

                        <barcode-field v-model="barcode" class="mb-3" ref="barcode" @focus="focusField = 'barcode'" @blur="onBlur"
                            @submit="scanItemToOrder" @openOrder="(b) => { setOrderNum(b); loadOrder(); }" />

                        <package-viewer :value="orderPackage" :items-assigned="order.assignedItems" :items-scanned="scannedItems" :changes="order.changes" inspection itemsonly />
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text v-show="compact" :class="order != null && order.changes.length > 0 ? 'modified rounded' : 'rounded'">
                <div v-if="order != null">
                    <div class="d-flex align-center">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip :color="orderStatusColor(order.status)" label class="white--text mb-3 headline" 
                                    v-bind="attrs" v-on="on">
                                    {{ order.foreignId }}
                                </v-chip>
                            </template>
                            <span>{{ order.status }}</span>
                        </v-tooltip>

                        <v-spacer></v-spacer>

                        <div class="mb-2" v-if="order.changes.length > 0">
                            <v-chip small label color="deep-orange" class="white--text">MODIFIED</v-chip>
                        </div>
                    </div>

                    <barcode-field v-model="barcode" class="mb-3" ref="barcode2" @focus="focusField = 'barcode2'" @blur="onBlur"
                        @submit="scanItemToOrder" @openOrder="(b) => { setOrderNum(b); loadOrder(); }" />

                    <package-viewer :value="orderPackage" :items-assigned="order.assignedItems" :items-scanned="scannedItems"  :changes="order.changes" inspection itemsonly />

                    <div class="subtitle-1 my-3">Comments</div>
                    <div>{{ order.comments == '' ? 'No comments available' : order.comments }}</div>
                </div>
            </v-card-text>

            <v-card-actions v-if="order != null">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn depressed @click="compact = !compact" icon v-on="on">
                            <v-icon>{{ compact ? 'mdi-view-compact-outline' : 'mdi-view-headline' }}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ compact ? 'Show full view' : 'Show compact view' }}</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-btn depressed color="grey darken-4" class="white--text" :loading="processing" @click="maybeNext">Continue</v-btn>
            </v-card-actions>
        </v-card>

        <force-dialog v-model="forceDialog" :text="forceDialogText" :forcing="forcing" @force="forceItemToOrder" />
        <force-dialog v-model="forceStatusDialog" :text="forceDialogText" btn-text="Yes, continue" :forcing="forcing" @force="forceLoadOrder" />

        <v-dialog v-model="continueDialog" width="500" persistent>
            <v-card>
                <v-card-title>Warning</v-card-title>
                <v-card-text>
                    There are items that has not been scanned yet. Are you sure you want to continue?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="continueDialog = false; onBlur();">Cancel</v-btn>
                    <v-btn depressed color="primary" @click="next">Yes, continue</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mask } from 'vue-the-mask';
import CustomerInfo from '@/components/CustomerInfo.vue';
import PackageViewer from '@/components/PackageViewer.vue';
import BarcodeField from '@/components/BarcodeField.vue';
import ForceDialog from '@/components/ForceDialog.vue';
import moment from 'moment';

export default {
    data: () => ({
        loadingView     : false,
        processing      : false,
        barcode         : '',
        focusField      : '',

        forceStatusDialog : false,

        forceDialog     : false,
        forceDialogText : '',
        forceReason     : '',
        forceReasons    : [],
        forcing         : false,

        continueDialog  : false,

        compact         : true // show compact version when true
    }),
    computed: {
        ...mapGetters({
            orderNum    : 'stations/finalInspection/orderNum',
            order       : 'stations/finalInspection/order',
            scannedItems: 'stations/finalInspection/scannedItems'
        }),
        orderPackage() {
            if( this.order == null )
                return {};

            let m = this.order.measurements;
            let items = [];
            this.order.items.forEach(i => {
                let size = '';
                if( i.categoryId == 'C' )       size = `${m.coatSize}${m.coatLength} - ${m.coatSleeve}`;
                else if( i.categoryId == 'P' )  size = `${m.pantWaist}${m.pantType} - ${m.pantOutseam}`;
                else if( i.categoryId == 'S' )  size = `${m.shirtSize}`;
                else if( i.categoryId == 'N' )  size = `${m.tieSize}`;
                else if( i.categoryId == 'V' )  size = `${m.vestSize}`;
                else if( i.categoryId == 'SH' ) size = `${m.shoeSize}${m.shoeType}`;
                else if( i.categoryId == 'CB' ) size = `${m.cummerbundSize}`;
                else                            size = '';

                items.push({
                    ...i,
                    size
                });
            });

            return {
                items: items,
            };
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.onBlur();
        });
    },
    methods: {
        ...mapActions({
            setOrderNum     : 'stations/finalInspection/setOrderNum',
            getOrder        : 'stations/finalInspection/loadOrder',
            clearOrder      : 'stations/finalInspection/clearOrder',
            scanItem        : 'stations/finalInspection/scanItem',
            forceItem       : 'stations/finalInspection/forceItem',
            process         : 'stations/finalInspection/process'
        }),
        async loadOrder( force = false ) {
            try {
                if( this.orderNum == '' )
                    return;

                this.playBeep2();

                this.loadingView = true;
                this.clearOrder();
                let res = await this.getOrder( force );
                this.loadingView = false;
                
                if( res && res.canBeForced === true ) {
                    this.playWarning();
                    this.forceDialogText = res.text;
                    this.forceStatusDialog = true;
                    return;
                }

                this.setOrderNum('');

                if( this.compact )
                    this.$refs.barcode2.focus();
                else
                    this.$refs.barcode.focus();


            }
            catch(error) {
                this.setOrderNum('');
                this.loadingView = false;
            }
        },
        async forceLoadOrder() {
            this.loadOrder( true );
            this.forceStatusDialog = false;
        },
        async scanItemToOrder() {
            try {
                if( this.barcode == '' )
                    return;

                let res = await this.scanItem( this.barcode );

                if( res && res.canBeForced === true ) {
                    this.playWarning();
                    this.forceDialogText = res.text;
                    this.forceReason = res.forceReason;
                    this.forceDialog = true;
                }
                else {
                    this.forceReasons = [];
                }

                let needed = this.order.items.filter(i => i.hasBarcode == true && i.categoryId != 'SH');
                if( needed.length != this.scannedItems.length )
                    this.$refs.barcode.focus();
                else
                    await this.next();
            }
            catch(error) {
                this.$refs.barcode.focus();
            }
            finally {
                this.barcode = '';
            }
        },
        async forceItemToOrder() {
            try {
                this.forcing = true;
                this.forceReasons.push( this.forceReason );
                this.forceReason = '';
                let res = await this.forceItem( this.forceReasons );
                this.forcing = false;

                this.forceDialog = false;

                if( res && res.canBeForced === true ) {
                    this.playWarning();
                    this.forceDialogText = res.text;
                    this.forceReason = res.forceReason;
                    this.forceDialog = true;
                }
                else {
                    this.forceReasons = [];
                }

                this.barcode = '';
                this.$refs.barcode.focus();

                let needed = this.order.items.filter(i => i.hasBarcode == true && i.categoryId != 'SH');
                if( needed.length != this.scannedItems.length )
                    this.$refs.barcode.focus();
                else
                    await this.next();
            }
            catch(error) {
                this.forcing = false;
                this.barcode = '';
                this.$refs.barcode.focus();
            }
        },
        maybeNext() {
            let needed = this.order.items.filter(i => i.hasBarcode == true && i.categoryId != 'SH');
            if( needed.length != this.scannedItems.length ) {
                this.continueDialog = true;
                return;
            }

            this.next();
        },
        async next() {
            // change order status to shipping
            try {
                this.processing = true;    
                await this.process();
                this.processing = false;

                this.clearOrder();
                this.$refs.orderNum.focus();
                this.playSuccess();
                this.mxShowMessage('Order set to Shipping Line', 'success');
            }
            catch(error) {
                this.processing = false;
            }
        },
        formatDate( date, f = 'YYYY-MM-DD' ) {
            return moment(date).format(f);
        },
        onBlur() {
            setTimeout(() => {
                if( this.order == null || this.focusField == 'orderNum' ) 
                    this.$refs.orderNum.focus();
                else if( this.compact )
                    this.$refs.barcode2.focus();
                else 
                    this.$refs.barcode.focus();
            }, 100);
        },
    },
    components: { CustomerInfo, PackageViewer, BarcodeField, ForceDialog },
    directives: { mask }
}
</script>

<style lang="scss" scoped>
.modified {
    border: 3px solid #FF5722;
}
</style>